@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Russo+One&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    scroll-behavior: smooth;
  }

body{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    height: 100vh;
    font-family: 'Montserrat', sans-serif !important;
}
  input:focus,
  button:focus,
  textarea:focus,
  select:focus {
    outline: 0;
  }
  
  .flex-colo {
    @apply flex flex-col justify-center items-center;
  }
  .flex-rows {
    @apply flex justify-center items-center;
  }
  .transitione {
    @apply transition ease-in-out duration-700;
  }

.main{
  background: linear-gradient(268.43deg, #93216C 5.3%, #23073A 53.26%);
}
.bt-linear{
  background: linear-gradient(90deg, #FD399B 0%, #3EAAE9 109.89%);
}
.hero{
  height: 600px;
}
.count{
  background: linear-gradient(180deg, #7529B3 36.04%, #FD399B 100%);

}
.top-hidden{
  @apply scale-0
}
.top-hover:hover .top-hidden{
  @apply scale-100
}

/* Owl */
.owl-theme .owl-dots .owl-dot span{
  background-color: unset !important;
  border: 2px solid #FD399B;
}
.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span{
  background-color: white !important;
}
.owl-theme .owl-dots{
 margin-top: 2rem !important;
}

@media (max-width:1024px) {
  .bgSmall{
    background: linear-gradient(180deg, #7529B3 36.04%, #FD399B 100%);
  }
}

.hero-btn {
  border-radius: 4px;
  font-size: 1.2rem;
  padding: 1.5rem 3rem;
}
